.i18n-ar-e-SA {
  #product-results {
    .product--teaser.product--shaded {
      .product__link-to-spp {
        float: #{$rdirection};
      }
    }
  }
  .store-locator {
    .select2-container {
      .select2-choice {
        .select2-arrow {
          b {
            transform: rotate(90deg);
          }
        }
      }
    }
    select {
      background-position: 5% 54%;
    }
    &.store-locator-results {
      .form--search--advanced {
        .top {
          .form--search {
            float: #{$ldirection};
            height: auto;
            .form-type-textfield {
              float: #{$ldirection};
              margin-#{$ldirection}: 5px;
            }
            .store-locator__submit {
              float: #{$ldirection};
            }
          }
          .icon--search {
            float: #{$ldirection};
            margin-#{$ldirection}: 5px;
          }
        }
        .geo_container {
          float: #{$ldirection};
        }
      }
      .results_panel {
        height: auto !important;
      }
    }
    .doors_results {
      .door_row {
        td {
          text-align: #{$ldirection};
        }
      }
    }
  }
  .site-container,
  .site-header {
    .header-search__typeahead-wrapper {
      .header-search__result--product {
        .header-search__result__link:after {
          content: '\200E'; //to avoid BiDi bracket issue
        }
      }
    }
  }
  .homepage-formatter__rows {
    .tabbed-products-block__content {
      .product-brief__footer {
        .product-price--sale {
          float: #{$rdirection};
          margin-right: 10px;
        }
      }
    }
  }
}

#product-page-livechat-hitarea {
  .lpEmtStarRating,
  .lpPoweredBy {
    display: none;
  }
}

.site-container {
  #contact_us {
    .field-container {
      .additional_question {
        width: 70%;
        @include swap_direction(border, 1px solid $color--gray--light);
        @include swap_direction(margin, 15px auto 35px);
        @include swap_direction(padding, 7px 0px);
        text-align: center;
        @include breakpoint($bp--xsmall-down) {
          width: 100%;
        }
      }
      .contact-additional-info {
        padding-top: 20px;
      }
    }
  }
  .email-customer-service-form {
    margin-right: 30px;
    #online-order-tip {
      display: none;
    }
    #questions {
      .question-details {
        display: none;
      }
    }
  }
  .email-artists-form {
    margin-right: 30px;
  }
  .customer-service-title,
  .email-artist-title,
  .customer-service-description {
    padding-right: 30px;
  }
  .search-results__results-container {
    .grid--mpp__item {
      .product__header {
        .product__name {
          @include swap_direction(padding, 0 95px 0 0);
        }
      }
    }
  }
  .product--teaser {
    .product__rating {
      .product__rating-non {
        @include breakpoint($bp--medium-up) {
          text-align: #{$rdirection};
        }
      }
    }
  }
}

ul.select2-results {
  max-height: 250px;
}

[dir='rtl'] {
  .grid--mpp-3across {
    .grid--mpp__item:nth-of-type(3) {
      margin-#{$rdirection}: 0px;
    }
  }
  .checkout__content {
    .cart-item__total {
      padding-#{$rdirection}: 0;
    }
    .order-summary__content {
      .value {
        padding-#{$rdirection}: 0;
      }
    }
  }
  select {
    background-position: 5% 54%;
    background-image: image-url('fallbacks/corner-triangle-reversed.png');
  }
  &.csstransforms {
    .sec-nav__item--has-sub-nav {
      .sec-nav__link:before {
        transform: rotate(225deg) !important;
      }
    }
    .shade-picker-dropdown {
      .select2-container {
        .select2-choice {
          .select2-arrow {
            b {
              transform: rotate(225deg) !important;
            }
          }
        }
      }
    }
  }
  .contact-us-page {
    .contact-nav {
      li:nth-of-type(2n + 1) {
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
      }
      .title {
        font-weight: bold;
      }
    }
  }
  .page--spp-ultra-wide {
    .page--spp__product {
      .shade-picker-dropdown {
        a.select2-choice {
          direction: ltr;
          text-align: #{$ldirection};
        }
      }
      .product__product-details-shade-name,
      .product__size {
        direction: ltr;
        text-align: #{$ldirection};
      }
    }
    .select2-drop {
      ul.select2-results {
        direction: rtl;
        text-align: #{$ldirection};
        li.select2-result {
          direction: ltr;
        }
      }
    }
  }
  .past-purchases {
    .products {
      li.product:nth-of-type(4n + 1) {
        margin-#{$ldirection}: 0;
      }
      li.product:nth-of-type(4n + 4) {
        margin-#{$rdirection}: 0;
      }
      @include breakpoint($bp--medium-down) {
        li.product:nth-of-type(3n + 1) {
          margin-#{$ldirection}: 0;
        }
        li.product:nth-of-type(3n + 3) {
          margin-#{$rdirection}: 0;
        }
      }
      .product--teaser {
        .product__footer {
          .notify-status {
            a.notify_me {
              float: #{$rdirection};
            }
          }
          .product-item__out-of-stock {
            margin-top: 10px !important;
            #{$ldirection}: auto !important;
            #{$rdirection}: 0px !important;
          }
        }
      }
    }
  }
  .favorites {
    .favorites-board__list {
      .pyramid-grid {
        li.grid--item:nth-of-type(4n + 4) {
          float: #{$ldirection} !important;
        }
        li.grid--item:nth-of-type(3n + 2) {
          margin-#{$ldirection}: 0 !important;
        }
        @include breakpoint($bp--medium-down) {
          li.grid--item {
            width: 25% !important;
          }
        }
      }
    }
  }
  .order-details-page {
    .order-info {
      @include breakpoint($bp--medium-down) {
        .order-info__item:nth-of-type(3n + 3) {
          margin-#{$rdirection}: 0;
        }
      }
    }
    .product-header {
      &.mobile_hidden {
        @include breakpoint($bp--medium-down) {
          display: block !important;
        }
      }
      .product {
        margin-#{$ldirection}: 0;
      }
    }
    .cart-item {
      .cart-item__price {
        &.mobile_hidden {
          @include breakpoint($bp--medium-down) {
            display: block !important;
          }
        }
      }
    }
  }
  &.csstransforms {
    .add-to-bag-bar__shade-picker {
      @include swap_direction(padding, 24px 15px 16px 5px);
      &:after,
      &:before {
        transform: rotate(-135deg);
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .product--teaser {
          .product__image {
            margin-#{$ldirection}: 0;
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
  #BVRR_Container {
    .BVDIBody.BVDI_QTBody {
      @include swap_direction(border, 1px solid $color--gray--lighter);
      float: #{$ldirection};
      width: 100%;
    }
    .BVSearchResults {
      float: #{$rdirection};
      line-height: 26px;
    }
    .BVRRDisplayContent {
      .BVRR_ReviewContainer {
        height: 370px;
      }
    }
  }
  #BV_SubmissionContainer {
    .BVStep1StyleDiv,
    .writeareview_BVRR .BVFooter {
      float: #{$ldirection};
      clear: #{$ldirection};
    }
  }
  .review-panel {
    .sub-section {
      &.address {
        float: none;
      }
    }
  }
}

.pg_wrapper.checkout.viewcart.panel {
  @include swap_direction(margin, 10px 0 0 0);
}

[dir='rtl'] {
  .site-header {
    .site-header__tools {
      .site-my-mac.active {
        .site-my-mac__contents {
          #{$ldirection}: auto !important;
        }
      }
    }
  }
}

.site-container {
  .artist--products {
    .artist--products__carousel-slide {
      .product__footer {
        a.notify_me {
          position: absolute !important;
          top: 0.2em;
          float: #{$rdirection};
          @include swap_direction(margin, 0);
        }
        .temp-outof-stock_text {
          float: #{$ldirection};
          width: 50%;
          position: absolute;
          #{$ldirection}: 55px;
          top: 0px;
          @include swap_direction(padding, 0);
        }
      }
    }
  }
}

[dir='rtl'] {
  .site-container {
    .artist--products {
      .artist--products__carousel-slide {
        .product__footer {
          .temp-outof-stock_text {
            float: #{$ldirection};
            width: 50%;
            position: absolute;
            #{$ldirection}: 55px;
            top: 0px;
            @include swap_direction(padding, 0);
            margin: 1em 1em 0 0;
          }
        }
      }
    }
  }
  .collection-tile-formatter {
    .collection-tile-formatter-inner {
      .cl-tile:nth-of-type(3n + 3) {
        margin-left: 0;
      }
    }
  }
}

.section-bestsellers.has-subnav {
  .site-header {
    height: 60px;
  }
  .site-container {
    header.mpp-custom__header--top {
      border-top: none;
      padding-top: 75px;
    }
  }
  .submenu--small-hide-all {
    display: block;
    position: fixed;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 101;
  }
}

.section-shipping {
  .customer-service__page {
    &-content {
      .basic-responsive-v1 {
        max-width: 850px;
      }
    }
  }
}

.section-counterfeit-education,
.section-supplier-relations,
.section-terms-conditions,
.section-privacy-policy {
  .site-container {
    .basic-formatter-v1 {
      .basic-responsive-v1 {
        max-width: 850px;
      }
    }
  }
}

.grid--mpp__item {
  .prod_inv_status-5 .inactive {
    display: block !important;
  }
  .product {
    &__price {
      .product {
        &__price--sale {
          float: #{$ldirection};
          #{$rdirection}: 4px;
          position: relative;
        }
      }
    }
  }
}

.footer-menu--pc.grid-container--5 {
  .grid--item:nth-of-type(5n + 1) {
    @include swap_direction(padding, 0 1.2em !important);
  }
}

@include breakpoint($bp--xlarge-up) {
  .collection-landing-brick__caption {
    bottom: 0;
    #{$ldirection}: 0;
    position: absolute;
    #{$rdirection}: 0;
    top: auto;
  }
}

.grid--mpp-3across.grid--mpp-hide-prices {
  .grid--mpp__item {
    .product_header_details {
      .product__name,
      .product__name product__name_short {
        display: block;
      }
    }
  }
}

.culture-detail {
  .culture-detail__header {
    .culture-detail__header-featured {
      text-transform: lowercase;
    }
  }
}

.section-our-story,
.section-viva-glam {
  .site-container {
    .basic-formatter-v1 {
      .row {
        .basic-responsive-v1 {
          br {
            display: none;
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .site-container {
    .customer-service__page {
      &-content {
        padding-top: 25px;
      }
    }
  }
}

.favorites {
  .favorites-board__list {
    .pyramid-grid {
      li.grid--item {
        width: 25% !important;
      }
    }
  }
}

.country-picker-v1 {
  @include swap_direction(padding, 18px);
  .dropdown-target {
    .form-item-wrapper {
      float: #{$ldirection};
      height: 57px;
      .form-item {
        margin-bottom: 0;
      }
    }
    .form-item-wrapper.country_container,
    .form-item-wrapper.language_container {
      width: 246px;
      @include swap_direction(padding, 0 5px 0 0);
    }
    .submit_container {
      .btn--no-border.submit {
        height: 58px;
        width: 228px;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .temp-out-of-stock-short__text {
          display: block;
          margin-top: 40px;
          position: absolute;
          #{$rdirection}: 0;
        }
      }
    }
  }
}

.section-viva-glam {
  .multi-use-tout {
    display: block;
  }
}

.ar_SA {
  #email_an_artist {
    .select2-container {
      .select2-arrow {
        top: 1px;
        height: 54px;
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .page--spp__product {
    .product--multishaded {
      ul.shade-picker__colors {
        li.shade-picker__color {
          height: 320px !important;
        }
      }
      .shade-picker__color-wrapper {
        min-height: 320px !important;
      }
    }
  }
}

.review-panel {
  .form-item {
    &.block {
      input {
        width: 65%;
      }
      &.checkbox {
        width: 100%;
        float: #{$ldirection};
      }
    }
    &.card-expiry-date {
      float: #{$ldirection};
      width: 45%;
      .select2-container {
        width: 15em !important;
      }
    }
    &.form-item.card-cvn {
      float: #{$ldirection};
      width: 27%;
      input {
        width: 11em;
        margin-#{$rdirection}: 1em;
      }
    }
  }
  .sub-section.address {
    float: #{$ldirection};
  }
}

@include breakpoint($bp--xlarge-down) {
  .page-products-products-skincare-moisturizers {
    .grid--mpp__item {
      height: 495px !important;
    }
  }
}

@include breakpoint($bp--large-down) {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          .temp-out-of-stock-short__text {
            margin-top: 15px !important;
          }
        }
      }
    }
  }
}

.section-collections-mac-selena {
  .shop-the-collection__quickshop.overlay-hover-wrapper {
    #product--prod_id-PROD43865 {
      .product__product-details-shade--single {
        border-top: 0px;
        .shade-picker__color-wrapper {
          min-height: 112px;
        }
      }
    }
  }
}

[dir='rtl'] {
  .page--spp__product {
    .product__detail {
      .product__price {
        .product__price--sale {
          float: right;
          padding-left: 5px;
        }
      }
    }
  }
  .spp-product-layout__content {
    .block-headline,
    .block-eyebrow {
      font-family: $ano-bold_regular-font;
      letter-spacing: normal;
    }
  }
}

.form--newsletter__form-items {
  .form-wrapper {
    .form--newsletter__submit {
      height: 29px !important;
      line-height: 35px !important;
    }
  }
}

.homepage-popup {
  #cboxContent {
    #cboxLoadedContent {
      background: black !important;
      overflow: hidden !important;
    }
  }
}

.select2-drop .select2-results li {
  height: auto;
}
