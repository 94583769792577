@if $password_strengthen {
  .new-account {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          bottom: 35%;
        }
      }
    }
  }
  .ar_SA {
    .password-field {
      &__info {
        &:before {
          @include breakpoint($bp--medium-up) {
            transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
          }
        }
      }
    }
  }
  .change-password {
    #cboxLoadedContent {
      height: 515px;
    }
  }
}
