.basic-portrait-tout-v1 {
  a.btn--fixed_width {
    width: 100%;
  }
}

body.section-privacy-policy {
  overflow: visible;
}

[dir='rtl'] {
  .custom-grid {
    .custom-grid__row:nth-child(2) {
      .custom-grid__item.unique-snowflake {
        margin-#{$rdirection}: 0px;
      }
    }
  }
  .carousel {
    .slick-prev {
      @include icon(arrow--left-medium);
      &:before {
        font-size: 12px;
      }
    }
    .slick-next {
      @include icon(arrow--right-medium);
      &:before {
        font-size: 12px;
      }
    }
  }
  .address-book-page__content.panel .address-book {
    margin-#{$ldirection}: 0;
  }
  .select2-container {
    .select2-choice {
      .select2-arrow b {
        transform: rotate(90deg) !important;
      }
    }
  }
  .checkoutdotcom-container {
    .date-container,
    .code-container {
      @include breakpoint($bp--small-up) {
        margin-left: 1em;
        margin-right: 0;
      }
    }
  }
}

.section-collections-soft-natural {
  .media-block {
    &__media--video {
      img {
        height: 380px;
      }
    }
    &__body {
      height: 38px;
    }
    &--video-duration {
      bottom: 35px;
    }
  }
}

.add-payment-page {
  @include swap_direction(padding, 0 0 1.5em 1em);
  .payment_address,
  .payment_method {
    @include breakpoint($bp--medium-up) {
      width: 45%;
      min-width: 450px;
    }
  }
  .payment_method {
    .form_element {
      margin-bottom: 1em;
    }
  }
  .payment_address {
    margin-bottom: 2em;
  }
  .address_controls {
    margin-top: 0.5em;
  }
  .select2-container,
  .field {
    margin-bottom: 1em;
    text-decoration: none;
  }
  #continue-btn,
  .btn {
    width: 228px;
  }
  .expiration_date_container {
    .select2-container {
      width: 10em !important;
      float: $ldirection;
      @include swap_direction(margin, 0 0.5em 1em 0);
    }
  }
}

.favorites-page__content {
  .favorites-board__list {
    .pyramid-grid {
      .grid--item:nth-of-type(4n + 1) {
        @include swap_direction(padding, 0 10px 0 0);
        @include swap_direction(margin, 0 !important);
      }
      .grid--item:nth-of-type(4n + 2) {
        @include swap_direction(padding, 0 10px 0 0);
        @include swap_direction(margin, 0 !important);
      }
      .grid--item:nth-of-type(4n + 3) {
        @include swap_direction(padding, 0 10px 0 0);
        @include swap_direction(margin, 0 !important);
      }
      .grid--item:nth-of-type(3n + 1) {
        @include swap_direction(padding, 0 10px 0 0);
        @include swap_direction(margin, 0 !important);
      }
    }
    .product__footer {
      .notify-status {
        @include swap_direction(padding, 0 !important);
        a.notify_me {
          position: absolute !important;
          @include swap_direction(margin, 0);
        }
      }
      .product-item__out-of-stock {
        position: absolute;
        top: 25px;
      }
    }
  }
}

.payment-info-page {
  @include swap_direction(padding, 0 20px 20px 10px);
}

.order-details-page {
  .product-header {
    display: block !important;
    .price {
      margin-#{$ldirection}: 60% !important;
      width: 12.33333%;
    }
    .quantity {
      margin-#{$ldirection}: 74.33333% !important;
      width: 12.33333%;
    }
  }
  .cart-item {
    .cart-item__qty {
      margin-#{$ldirection}: 75.33333% !important;
    }
    .cart-item__price.mobile_hidden {
      margin-#{$ldirection}: 60% !important;
      display: block !important;
    }
  }
}

.address.fs {
  .form_element {
    width: 50%;
    float: $ldirection;
    padding-#{$rdirection}: 1em;
    margin-bottom: 15px;
    &.country_container,
    &.address2_container,
    &.default_ship_container {
      width: 100%;
    }
  }
  #google_map_locate_address {
    float: $ldirection;
  }
}

.address-overlay-billing {
  .address.fs {
    .form_element.phone_2_container {
      width: 50%;
    }
    .form_element.address1_container {
      width: 100%;
    }
  }
}

#email_an_artist {
  .select2 {
    &-choice {
      position: static;
      filter: none;
    }
    &-arrow {
      filter: none;
      height: auto;
    }
  }
}

@include breakpoint($bp--large-down) {
  .favorites-page {
    .favorites-board__item {
      .product__footer {
        padding-top: 2px;
        .favorite-actions {
          top: 23px;
        }
      }
    }
  }
}

.address-overlay-billing {
  .form_element.state_1_container,
  .form_element.state_3_container {
    display: none;
  }
}

.site-container {
  .product__reviews-spp {
    #BVRR_Container {
      .BVRRRatingSummaryLinkWriteFirst {
        display: none;
      }
      @include breakpoint($bp--large-down) {
        .BVRRRatingSummaryLinkWriteFirst {
          display: block;
        }
      }
    }
  }
}

.checkoutdotcom-container--account {
  .checkoutdotcom-field {
    @include swap_direction(border, 1px solid $color--gray--lightest);
    @include swap_direction(border-radius, 0);
    height: 60px;
    margin: 1em 0;
    padding: 0 12px;
    &.error {
      border-color: $color--red;
      box-shadow: none;
    }
  }
  .date-container,
  .code-container {
    @include breakpoint($bp--small-up) {
      width: 40%;
      float: $ldirection;
      margin-right: 1em;
    }
  }
}

.payment-info {
  &-page {
    padding-bottom: 1.5em;
    .grid_container {
      @include breakpoint($bp--medium-up) {
        @include layout($grid--2, 1em) {
          .payment-info__default {
            @include grid-span(1, 1);
          }
          .payment-info__others {
            @include grid-span(1, 2);
          }
        }
      }
    }
  }
  &__subheader {
    margin: 1em 0 0;
  }
  &__default,
  &__others {
    .column {
      margin-top: 35px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
