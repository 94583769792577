.tabbed-products-block {
  &__tab {
    width: 25% !important;
  }
}
.site-header {
  .site-header {
    &__tools {
      .site-email-signup {
        &__contents {
          #{$ldirection}: -123px;
        }
      }
    }
  }
}
