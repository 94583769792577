[dir='rtl'] {
  @mixin margin-padding($pixel) {
    margin-#{$ldirection}: $pixel;
    padding-#{$ldirection}: $pixel;
  }
  .checkout__new-account {
    @include margin-padding(0px);
  }
  .order-details-page,
  .checkout__content {
    .cart-item {
      .cart-item__thumb {
        @include margin-padding(0px);
      }
    }
  }
  .order-details-page {
    .order-products {
      .product-header {
        .product {
          margin-#{$ldirection}: 0;
          padding-#{$ldirection}: 0;
        }
      }
    }
  }
  .checkout {
    .product__thumb {
      @include margin-padding(0px);
    }
  }
  #viewcart-panel {
    .cart-items {
      .cart-header {
        .cart-header__title--products {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
  #recommended-products-panel {
    .recommended-product-items {
      .recommended-item:nth-of-type(3n + 1) {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .column.right {
    #order-summary-panel {
      .order-summary__content {
        .label {
          margin-#{$ldirection}: 0;
          padding-#{$ldirection}: 0;
        }
      }
    }
  }
  .checkout.viewcart {
    .continue-shopping {
      margin-#{$ldirection}: 1.5em;
    }
  }
  #confirmation-page {
    #registration-panel {
      margin-right: 0;
      .messages-container {
        float: right;
      }
      .content {
        float: right;
      }
      .form-item {
        .password {
          .invalid_marker {
            #{$rdirection}: 8px !important;
          }
        }
      }
    }
  }
  .field-container--grid {
    .form-item:nth-of-type(2n + 1) {
      .valid_marker,
      .invalid_marker {
        #{$rdirection}: 18px;
        #{$ldirection}: auto;
      }
    }
    .select2-container {
      .select2-choice {
        .select2-arrow {
          left: 24px;
        }
      }
    }
  }
  .checkout {
    .samples-page {
      .samples-grid {
        .samples-grid--item:nth-of-type(4n + 1) {
          width: 25%;
          margin-right: 0;
          clear: both;
        }
        .samples-grid--item:nth-of-type(4n + 4) {
          margin-right: 75%;
        }
      }
    }
  }
  .checkoutdotcom-container {
    .date-container,
    .code-container {
      @include breakpoint($bp--small-up) {
        margin-left: 1em;
        margin-right: 0;
      }
    }
  }
}

#review-panel {
  .address.two-column-form {
    .view-address {
      @include swap_direction(margin, 0 0 15px 0);
      .btn.change-address {
        border-bottom: 1px solid $color--gray--lightest;
        min-width: 0;
        @include swap_direction(padding, 0);
        height: 10px;
        line-height: 1.5px;
        background: none;
        color: $color--black;
        font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
        font-size: 12px;
      }
    }
    .select-address.form-container {
      span.address-to-use {
        display: inline-block;
        float: $ldirection;
        @include swap_direction(margin, 0 50px 10px 0px);
        label {
          max-width: 100%;
        }
      }
      .delivery-address {
        float: $ldirection;
        width: 100%;
      }
      .existing-address {
        float: $ldirection;
        width: 100%;
      }
    }
  }
  .hidden_nojs {
    float: $ldirection;
    section.divide {
      @include swap_direction(margin, 0 0 15px 0);
    }
  }
  #checkout_review {
    .form-item.checkbox.error {
      @include swap_direction(border, 1px solid red);
    }
  }
}

#checkout_billing_panel {
  .related-media span {
    @include swap_direction(padding, 0 5px);
  }
}

.column.right.checkout__sidebar {
  #viewcart-shopping-bag-panel {
    .viewcart-panel__content {
      .cart-item__total {
        text-align: $ldirection;
        margin-top: 1.25em;
      }
    }
  }
}

.checkout__content {
  .select2-container.error {
    @include swap_direction(border, 1px solid red);
  }
  .form-item {
    &.card-expiry-date {
      position: inherit;
    }
  }
}

#google_map_locate_address {
  @include swap_direction(border, 2px solid);
  height: 300px;
  width: 500px;
  #floating-panel {
    width: 500px;
    height: 20px;
    input#address-latlng {
      width: 33%;
    }
  }
  #google_pick_map {
    width: 496px;
    height: 276px;
  }
}

#shipping-panel {
  #checkout_shipping_panel {
    .select-address.form-container {
      float: $ldirection;
      width: 100%;
      @include swap_direction(margin, 0 0 15px 0);
      .personal-details {
        .set-pickup-point {
          .select-pickup-point {
            .select2-container {
              width: 50% !important;
              float: #{$ldirection};
            }
            .select2-container:first-child {
              width: 48% !important;
              @include swap_direction(margin, 0 1.2em 0 0);
            }
          }
        }
      }
    }
    #continue-btn {
      padding-top: 12px;
    }
    .email-and-sms-promotions {
      width: auto;
      section {
        float: $ldirection;
        width: 100%;
        @include swap_direction(margin, 0 0 15px 0);
        &.divide {
          @include swap_direction(margin, 0);
        }
      }
    }
  }
}

#shipmethod-panel {
  .ship-method-group-label label {
    width: 80%;
    line-height: 20px;
  }
}

@include breakpoint($bp--large-down) {
  .checkout.viewcart {
    #top-viewcart-buttons {
      width: 100% !important;
      .continue-buttons {
        text-align: center;
      }
    }
    #bottom-viewcart-buttons {
      .continue-buttons {
        width: 100% !important;
        text-align: center;
      }
    }
  }
}

.checkout__content {
  .cart-item__total,
  .cart-header__title--total {
    width: 14.66667%;
  }
  .cart-header__title--total {
    @include swap_direction(padding, 0 0 0 0 !important);
  }
  #order-summary-panel {
    .order-summary__content {
      .value {
        width: 14.66667%;
      }
    }
  }
}

.checkout.viewcart {
  #top-viewcart-buttons {
    width: 55%;
    .continue-buttons {
      @include swap_direction(padding, 0 0 0 0 !important);
    }
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      width: 74%;
      @include swap_direction(padding, 0 0 0 0 !important);
    }
  }
}

.viewcart {
  .checkout-header {
    .checkout-header__title {
      width: 100%;
      padding: 0;
    }
    #promo-message {
      width: 100%;
    }
    #top-viewcart-buttons {
      width: 100%;
      .continue-buttons {
        text-align: center;
      }
    }
    .checkout-header__samples {
      text-align: center;
      margin: 10px;
    }
  }
}

@include breakpoint($bp--xxlarge-up) {
  #shipping-panel {
    #checkout_shipping_panel {
      .select-address {
        .select-menu select {
          display: none !important;
        }
      }
    }
  }
}

[dir='rtl'] {
  .site-container {
    .checkout__sidebar {
      .viewcart-header {
        padding: 2em !important;
        h2 {
          float: $ldirection;
          padding: 0 0 0 0;
          margin: -10px;
        }
        #header-item-count {
          float: $rdirection;
          margin: -10px;
        }
      }
    }
  }
}

.review-panel {
  .form-item.card-cvn,
  .form-item.card-expiry-date {
    float: unset !important;
  }
}

.product--shaded--single {
  .product__product-details-shade--single {
    display: none;
  }
}

bdo {
  &.phone-direction {
    direction: ltr;
  }
}

.checkoutdotcom-container {
  .checkoutdotcom-field {
    @include swap_direction(border, 1px solid $color--gray--lightest);
    @include swap_direction(border-radius, 0);
    @include swap_direction(margin, 1em 1em 1em 0);
    height: 60px;
    padding: 0 12px;
    &.error {
      border-color: $color--red;
      box-shadow: none;
    }
  }
  .card-number-frame {
    @include breakpoint($bp--large-up) {
      width: 65%;
    }
  }
  .date-container,
  .code-container {
    @include breakpoint($bp--small-up) {
      width: 40%;
      float: $ldirection;
      margin-right: 1em;
    }
    @include breakpoint($bp--large-up) {
      width: 11em;
    }
  }
}
